import React, { memo, useEffect, useRef, useState, type ReactNode, useCallback } from 'react';
import * as Sentry from "@sentry/browser";
import StandardUploader from '../services/StandardUploader';
import MultipartUploader from '../services/MultipartUploader';

import { 
  abortMultipartUpload, 
  completeMultipartUpload, 
  createMultipartUpload,
  signPartUpload,
  signUpload
} from "../services/ContentEndpoints";

import { useTimeRemaining } from '../hooks/useTimeRemaining';

interface UploadFileProps {
  prefix: string;
  file: File;
  shouldUseMultipart?: boolean;
  chunkSize?: number,
  retries?: number;
  beforeUpload?: (file: File) => Promise<string>;
  beforeChunkUpload?: (file: File, chunk?: number) => Promise<string>;
  onStarted?: () => void;
  onSuccess?: Function;
  onError?: (error: Error) => void;
  onAbort?: () => void;
  children: (progress: Progress, abort: () => void) => ReactNode;
}

export interface Progress {
  sent: number;
  total: number;
  percentage: number;
  pendingTime: number;
}

export const UploadFile = memo(
  ({
    prefix,
    file,
    shouldUseMultipart = false,
    chunkSize,
    retries = 0,
    onStarted,
    onSuccess,
    onError,
    onAbort,
    children,
  }: UploadFileProps) => {
    const uploadId = useRef(null);
    const startedAt = useRef(null);

    const { updateTimeRemaining } = useTimeRemaining();

    const [progress, setProgress] = useState<Progress>({
      sent: 0,
      total: 0,
      percentage: 0,
      pendingTime: 0,
    });

    const isUploading = useRef(false);

    const fetchChunkedUrlCallback = useCallback(async (file: File, chunk: number) => {
      const res = await signPartUpload(uploadId.current, chunk + 1, prefix + file.name);

      return res.url;
    }, [prefix]);

    const fetchUrlCallback = useCallback(async (file: File) => {
      const res = await signUpload(`${prefix}${file.name}`);

      return res.url;
    }, [prefix]);

    const beforeUploadCallback = useCallback(async (file: File): Promise<string> => {
      const res = await createMultipartUpload(prefix + file.name, file.type);

      uploadId.current = res.uploadId;

      return res.key;
    }, [prefix]);

    const handleProgress = (ev: CustomEvent) => {
      const detail: ProgressEvent = ev.detail;

      setProgress({
        sent: detail.loaded,
        total: detail.total,
        percentage: Math.round((detail.loaded / detail.total) * 100),
        pendingTime: updateTimeRemaining(startedAt.current, detail.loaded, detail.total)
      })
    }

    const handleError = useCallback(async (ev: any) => {
      console.log(ev.detail, uploadId.current, `${prefix}${file.name}`);

      if (shouldUseMultipart) {
        try {
          // try/catch because we want to carry on, if this request fails
          await abortMultipartUpload(uploadId.current, `${prefix}${file.name}`);  
        } catch (error) {
          Sentry.captureException(error);
        }        
      }

      onError?.(new Error('foo'));
    }, [file.name, onError, prefix, shouldUseMultipart]);

    const handleSuccess = useCallback(async (ev: CustomEvent) => {
      if (shouldUseMultipart) {
        const parts = ev.detail.parts;

        await completeMultipartUpload(uploadId.current, `${prefix}${file.name}`, parts);
      }

      onSuccess?.();
    }, [file.name, onSuccess, prefix, shouldUseMultipart]);

    const [uploader] = useState(() => {
      if (shouldUseMultipart) {
        return new MultipartUploader({
          config: {
            maxRetries: retries,
            chunkSize: chunkSize,
            threads: 4
          },
          callbacks: {
            beforeUpload: beforeUploadCallback,
            fetchUrl: fetchChunkedUrlCallback,
          }   
        })
      }
        
      return new StandardUploader({
        config: {
          maxRetries: retries,
        },
        callbacks: {
          fetchUrl: fetchUrlCallback
        }          
      });
    });

    useEffect(() => {
      uploader.addEventListener('progress', handleProgress);
      uploader.addEventListener('success', handleSuccess);
      uploader.addEventListener('error', handleError);
      
      return () => {
        uploader.removeEventListener('progress', handleProgress);
        uploader.removeEventListener('success', handleSuccess);
        uploader.removeEventListener('error', handleError);
      }
    });

    const abort = () => {
      uploader.abort();

      if (shouldUseMultipart) {
        abortMultipartUpload(uploadId.current, `${prefix}${file.name}`);
      }      

      onAbort?.();
    }

    useEffect(() => {
      if (!isUploading.current) {
        onStarted?.();

        isUploading.current = true;
        startedAt.current = new Date().getTime();

        uploader.start(file);
      }
    }, [uploader, file, onStarted]);

    return <>{children(progress, abort)}</>;
  }
);

UploadFile.displayName = 'UploadFile';