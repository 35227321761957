import { wait } from "../utils";
import type { ProgressEvent } from "./types";

type Url = string;

export type FetchUrl = (file: File) => Promise<Url>;

interface StandardUploaderOptions {
  config: {
    timeout?: number;
    maxRetries: number;
  },
  callbacks: {
    fetchUrl: FetchUrl,
  }
}

function log(message: string, ...args): void {
  console.log(`[StandardUploader]: ${message}`, ...args);
}

/**
 * Standard file uploader. It handles non-multipart file uploads.
 * @see https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
 */

class StandardUploader extends EventTarget {

  private aborted = false;
  private file: File;
  private maxRetries: number;
  private timeout: number|undefined;

  private connection: XMLHttpRequest;
  
  private fetchUrl: FetchUrl;

  constructor(options: StandardUploaderOptions) {
    super();

    this.maxRetries = options.config.maxRetries;
    this.timeout = options.config.timeout || 0;
    
    this.fetchUrl = options.callbacks.fetchUrl;
  }
  
  async start (file: File) {
    this.file = file;

    this.sendNext();
  }

  private async sendNext (retry = 0) {
    if (this.file) {
      const url = await this.fetchUrl(this.file);

      this.upload(url)
        .then((res: XMLHttpRequest) => {
          this.dispatchEvent(new CustomEvent('success'));
        })
        .catch((error: Error) => {  
          if (this.aborted) {
            this.file = null;

            return;
          }

          if (retry < this.maxRetries){
            retry++;
            
            //exponential backoff retry before giving up
            log(`Failed to upload, backing off ${2 ** retry * 100} before retrying...`, error);

            wait(2 ** retry * 100).then(() => {              
              this.sendNext(retry);
            });
          } else {
            log('Failed to upload, giving up');

            this.dispatchEvent(new CustomEvent('error', {
              detail: error
            }));
          }
        });
    }
  }

  private upload(url: Url): Promise<any> {
    return new Promise((resolve, reject) => {
      const throwXHRError = (error, abortFx) => {    
        delete this.connection;

        reject(error);

        window.removeEventListener('offline', abortFx);
      }

      if (!window.navigator.onLine) {
        reject(new Error("System is offline"));
      }

      const xhr = (this.connection = new XMLHttpRequest());
      
      xhr.timeout = this.timeout;
      
      const abortXHR = () => xhr.abort();

      xhr.upload.addEventListener('loadstart', () => {
        log(`${this.file.name} started`)
      });

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress: ProgressEvent = {
            loaded: event.loaded,
            total: event.total
          };

          this.dispatchEvent(new CustomEvent('progress', {
            detail: progress
          }));
        }
      });

      // Fired when an XMLHttpRequest transaction completes successfully. 
      xhr.addEventListener('load', (ev: any) => {
        if (ev.target.status === 200) {
          window.removeEventListener('offline', abortXHR);

          return resolve(xhr);
        }

        this.dispatchEvent(new CustomEvent('error'));

        return reject();
      });

      xhr.addEventListener('error', (ev) => {
        log(`${this.file.name} errored`, ev);
        
        return reject();
      });

      xhr.open("PUT", url);
      xhr.setRequestHeader('Content-Type', this.file.type);

      // xhr.ontimeout = (error) => {
      //   throwXHRError(error, abortXHR);
      // }
      xhr.onabort = () => {
        throwXHRError(new Error("Upload canceled by user or system"), abortXHR);
      }

      window.addEventListener('offline', abortXHR);

      xhr.send(this.file);
    })
  }

  abort() {
    this.aborted = true;

    if (this.connection) {
      this.connection.abort();
    }
  }
}

export default StandardUploader;
