import React, { type ReactNode, useState } from 'react';
import { createRef, useEffect, useMemo } from 'react';
import Dropzone, { type DropzoneRef, type FileWithPath } from 'react-dropzone';
import pluralize from 'pluralize';
import { UploadStatus, type FootageType, type UploaderFile, type RejectedFile } from "../types";
import { type Progress, UploadFile } from "./UploadFile";
import { humanFileSize, humanTime } from "../utils";
import Backdrop from "../components/Backdrop";
import WarningModal from "./WarningModal";
import { useFootageFiles } from "../hooks/useFootageFiles";
import upload from "images/upload.svg";
import styles from './generic-uploader.module.scss';

type UploadId = string;

type OnRemove = () => void;

type OnRetry = () => void;

interface Props {
  accept: Record<string, Array<string>>;
  prefix?: string;
  shouldUseMultipart: (file: File) => boolean;
  retries?: number;
  threads: number;
  getChunkSize?: (file: File) => number;
  signUpload?: (file: File) => Promise<string>;
  createMultipartUpload?: (file: File) => Promise<UploadId>;
  signPart?: (file: File, chunk: number) => Promise<string>;
  onUploadCompleted?: (file: File) => void;
  onIsProcessingChange?: (isProcessing: boolean) => void;
}

const borderColor = (focused: boolean, accepted: boolean, rejected: boolean): string => {
  if (focused) return '#2196f3';
  
  if (accepted) return '#00e676';
  
  if (rejected) return '#ff1744';
}

const dropzoneDisabledStyles = () => {
  return {
    borderColor: '#eeeeee',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    cursor: 'not-allowed'
  }
}

export default function FootageUploader (props: Props) {
  const { onIsProcessingChange } = props;
  const dropzoneRef: React.RefObject<DropzoneRef> = createRef();

  const [uploadStarted, setUploadStarted] = useState(false);
  const [showLogWarning, setShowLogWarning] = useState(false);

  const { files, rejectedFiles, isProcessing, handleDrop, processNext, removeApprovedFile, removeRejectedFile, removeAllFiles, markUploaded, markFailed, markAborted, markQueued } = useFootageFiles(props.threads);

  const uniqueClipsCount = files.size;

  const trackableFiles = Array.from(files.values());

  const colorCount = useMemo(() => {
    return trackableFiles.reduce((previousValue: number, currentValue: Record<FootageType, UploaderFile>) => {
      if (currentValue.color) {
        return previousValue + 1;
      }

      return previousValue;
    }, 0);
  }, [trackableFiles]);

  const logCount = useMemo(() => {
    return trackableFiles.reduce((previousValue: number, currentValue: Record<FootageType, UploaderFile>) => {
      if (currentValue.log) {
        return previousValue + 1;
      }

      return previousValue;
    }, 0);
  }, [trackableFiles]);

  const uploadedCount = useMemo(() => {
    return trackableFiles.reduce((previousValue: number, currentValue: Record<FootageType, UploaderFile>) => {
      let count = 0;

      if (currentValue.color?.status === UploadStatus.Success) {
        count = count + 1;
      }

      if (currentValue.log?.status === UploadStatus.Success) {
        count = count + 1;
      }

      return previousValue + count;
    }, 0);
  }, [trackableFiles]);

  const queuedCount = useMemo(() => {
    return trackableFiles.reduce((previousValue: number, currentValue: Record<FootageType, UploaderFile>) => {
      let count = 0;

      if (currentValue.color?.status === UploadStatus.Queued || currentValue.color?.status === UploadStatus.MissingLog) {
        count = count + 1;
      }

      if (currentValue.log?.status === UploadStatus.Queued) {
        count = count + 1;
      }

      return previousValue + count;
    }, 0);
  }, [trackableFiles]);

  const isDone = uploadStarted && queuedCount === 0;

  useEffect(() => {
    onIsProcessingChange?.(isProcessing);
  }, [isProcessing, onIsProcessingChange]);

  const openDialog = () => {
    if (dropzoneRef.current?.open) {
      dropzoneRef.current.open()
    }
  };

  const usesMultipartUpload = (file: FileWithPath) => {
    return props.shouldUseMultipart(file);
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (queuedCount) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [queuedCount]);

  const handleSuccess = (key: string, type: FootageType, file: File) => {
    markUploaded(key, type);
    
    props.onUploadCompleted(file);

    processNext();
  }

  const handleError = (key: string, type: FootageType, error: Error) => {
    markFailed(key, type, error);

    processNext();
  }

  const handleRetry = (key: string, type: FootageType) => {
    markQueued(key, type);

    if (! isProcessing) {
      processNext();
    } 
  }

  const startUpload = () => {
    if (logCount === 0) {
      setShowLogWarning(true);

      return;
    }

    setUploadStarted(true);

    // Note (Maurizio): in dev mode, React renders everything twice,
    // therefore if threads is 2, it will trigger 4 uploads at once.
    // Just keep this in mind or you will lose your sanity.
    for (let i = 0; i < props.threads; i++) {
      processNext(); 
    }
  }

  const continueAndStartUpload = () => {
    setShowLogWarning(false);

    processNext();
  }

  const handleAborted = (key: string, type: FootageType) => {
    markAborted(key, type);

    processNext();
  }

  return (
    <div>
      {uploadedCount > 0 && (
        <div className={styles.statusBar}>{uploadedCount} of {colorCount + logCount} {pluralize('file', colorCount + logCount)} successfully uploaded</div>
      )}
      <div className={styles.uploadContainer}>
        <div>
          <h3>Summary</h3>
          <ul>
            <li>{colorCount + logCount} Total {pluralize('File', colorCount + logCount)}</li>
            <li>{uniqueClipsCount} Unique {pluralize('Clip', uniqueClipsCount)}</li>
            <li>{colorCount} Color {pluralize('File', colorCount)}</li>
            <li>{logCount} Log {pluralize('File', logCount)}</li>
            <li>{rejectedFiles.length} Rejected {pluralize('File', rejectedFiles.length)}</li>
          </ul>
        </div>
        <Dropzone ref={dropzoneRef} disabled={isProcessing || isDone} onDrop={handleDrop} accept={props.accept} noClick noKeyboard>
          {({getRootProps, getInputProps, isFocused, isDragAccept, isDragReject}) => (
            <section>
              <div {...getRootProps({className: styles.dropzone})} style={(isProcessing || isDone) ? dropzoneDisabledStyles() : { borderColor: borderColor(isFocused, isDragAccept, isDragReject)}}>
                <input {...getInputProps()} />
                <p>
                  Drag and drop files or folders or {
                    (isProcessing || isDone)
                      ? 'click'
                      : <button className={styles.linkButton} type="button" onClick={openDialog}>click</button> 
                  } to select files to start uploading.
                </p>
                <img src={upload} alt="upload icon" height={40} draggable="false" />
              </div>
            </section>
          )}
        </Dropzone>
        <div>
          <h3>Invalid Files</h3>
          <table width="100%" className={styles.uploaderTable}>
            <thead>
              <tr>
                <th style={{ width: "40%" }}>Filename</th>
                <th style={{ width: "40%" }}>Reason</th>
                <th style={{ width: "20%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
            {rejectedFiles.length
              ? rejectedFiles.map((rejection: RejectedFile) => (
                <ErroredRow key={rejection.id}>
                  <td>{rejection.file.name}</td>
                  <td>{rejection.error}</td>
                  <td style={{ textAlign: "center"}}>
                    <DeleteButton title="Delete" onClick={() => removeRejectedFile(rejection.id)}>&times;</DeleteButton>
                  </td>
                </ErroredRow>
              )) : (
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>No Files</td>
              </tr>
              )
            }
            </tbody>
          </table>

          <h3>
            Queued Files
            &nbsp;
            {(files.size > 0 && !uploadStarted) && <DeleteButton title="Remove All" onClick={removeAllFiles}>Remove All</DeleteButton>}
          </h3>

          <table width="100%" className={styles.uploaderTable}>
            <thead>
              <tr>
                <th style={{ width: "35%"}}>File Name</th>
                <th style={{ width: "15%"}}>Format Type</th>
                <th style={{ width: "35%"}}>Status</th>
                <th style={{ width: "10%"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.size 
                ? Array.from(files).map(([key, value]) => (
                  <React.Fragment key={key}>
                    <tr>
                      <td colSpan={4}><b>{key}</b></td>
                    </tr>
                    <RenderFile uploaderFile={value.color} type="color" onRetry={() => handleRetry(key, "color")} onRemove={() => removeApprovedFile(key, "color")}>
                      {value.color?.status === UploadStatus.Processing && (
                        <UploadFile
                          prefix={props.prefix}
                          file={value.color.file}
                          retries={props.retries}
                          chunkSize={usesMultipartUpload(value.color.file) ? props.getChunkSize(value.color.file) : null}
                          shouldUseMultipart={usesMultipartUpload(value.color.file)}
                          beforeUpload={usesMultipartUpload(value.color.file) ? props.createMultipartUpload : null}
                          beforeChunkUpload={usesMultipartUpload(value.color.file) ? props.signPart : props.signUpload}
                          onSuccess={() => handleSuccess(key, "color", value.color.file)}
                          onError={(error: Error) => handleError(key, "color", error)}
                          onAbort={() => handleAborted(key, "color")}
                        >
                          {(progress: Progress, abort) => (
                            <ProgressRow progress={progress.percentage}>
                              <td>{value.color.file.name}</td>
                              <td>color</td>
                              <td>
                                {humanFileSize(progress.sent, 2)} / {humanFileSize(value.color.file.size, 2)}
                                &nbsp;
                                -
                                &nbsp;
                                [{humanTime(progress.pendingTime)}] - {progress.percentage}%
                              </td>
                              <td style={{ textAlign: "center"}}>
                                <RetryButton title="Abort" onClick={() => confirm('Do you really want to abort this upload? It can be resumed later.') ? abort() : null}>
                                &crarr;
                                </RetryButton>
                              </td>
                            </ProgressRow>
                          )}
                        </UploadFile>    
                      )}
                    </RenderFile>

                    <RenderFile uploaderFile={value.log} type="log" onRetry={() => handleRetry(key, "log")} onRemove={() => removeApprovedFile(key, "log")}>
                      {value.log?.status === UploadStatus.Processing && (
                        <UploadFile
                          prefix={props.prefix}
                          file={value.log.file}
                          retries={props.retries}
                          chunkSize={usesMultipartUpload(value.log.file) ? props.getChunkSize(value.log.file) : null}
                          shouldUseMultipart={usesMultipartUpload(value.log.file)}
                          beforeUpload={usesMultipartUpload(value.log.file) ? props.createMultipartUpload : null}
                          beforeChunkUpload={usesMultipartUpload(value.log.file) ? props.signPart : props.signUpload}
                          onSuccess={() => handleSuccess(key, "log", value.log.file)}
                          onError={(error: Error) => handleError(key, "log", error)}
                          onAbort={() => handleAborted(key, "log")}
                        >
                          {(progress: Progress, abort) => (
                            <ProgressRow progress={progress.percentage}>
                              <td>{value.log.file.name}</td>
                              <td>log</td>
                              <td>
                                {humanFileSize(progress.sent, 2)} / {humanFileSize(value.log.file.size, 2)}
                                &nbsp;
                                -
                                &nbsp;
                                [{humanTime(progress.pendingTime)}] - {progress.percentage}%
                              </td>
                              <td style={{ textAlign: "center"}}>
                                <RetryButton title="Abort" onClick={() => confirm('Do you really want to abort this upload? It can be resumed later.') ? abort() : null}>
                                &crarr;
                                </RetryButton>
                              </td>
                            </ProgressRow>
                          )}
                        </UploadFile>    
                      )}
                    </RenderFile>
                  </React.Fragment>
                ))
                : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>No Files yet - <button className={styles.linkButton} type="button" onClick={openDialog}>Select Files</button></td>
                  </tr>
                ) 
            }
            </tbody>
          </table>
        </div>
        {!uploadStarted && (
          <div className={styles.uploadButton}>
            <button type="button" onClick={startUpload} disabled={files.size === 0}>Start Upload</button>
          </div>
        )}
      </div>

      {showLogWarning && (
        <Backdrop>
          <WarningModal
            dismissText="Go Back"
            continueText="Continue"
            onDismiss={() => setShowLogWarning(false)}
            onContinue={continueAndStartUpload}
          >
            <p><b>No LOG Files detected</b></p>
            <p>If you plan to include log files, please ensure all log and color files are uploaded in the same session.</p>
          </WarningModal>
        </Backdrop>
      )}      
    </div>
  );
}

const RenderFile = (props: { children: JSX.Element, type: FootageType, onRetry: OnRetry, onRemove: OnRemove, uploaderFile?: UploaderFile }) => {
  const { type, onRetry, onRemove, uploaderFile } = props;

  if (!uploaderFile) {
    return (
      <RenderRow uploaderFile={null}>
        <td>No File Detected</td>
        <td>{type}</td>
        <td />
        <td />
      </RenderRow> 
    )
  }

  if (uploaderFile.status !== UploadStatus.Processing) {
    return (
      <RenderRow uploaderFile={uploaderFile}>
        <td>{uploaderFile.file.name}</td>
        <td>{type}</td>
        <td>
          {uploaderFile.status === UploadStatus.Aborted && "Aborted"}
          {uploaderFile.status === UploadStatus.Queued && "Queued"}
          {uploaderFile.status === UploadStatus.Success && "Uploaded!"}
          {uploaderFile.status === UploadStatus.MissingLog && "Warning: No Log File"}
          {uploaderFile.status === UploadStatus.MissingColor && "Error: Missing Color File"}
        </td>
        <td style={{ textAlign: "center"}}>
          {(uploaderFile.status !== UploadStatus.Aborted && uploaderFile.status !== UploadStatus.Success) && (
            <DeleteButton onClick={onRemove}>&times;</DeleteButton>
          )}
          {uploaderFile.status === UploadStatus.Aborted && (
            <RetryButton onClick={onRetry} title='Retry upload'>&crarr;</RetryButton>
          )}
        </td>
      </RenderRow> 
    );
  }

  return props.children;
}

const RenderRow = (props: { children: JSX.Element[], uploaderFile?: UploaderFile }) => { 
  const { children, uploaderFile } = props;

  if (!uploaderFile) {
    return <WarningRow animated={false}>{children}</WarningRow>;   
  }

  switch (uploaderFile.status) {
    case UploadStatus.MissingLog:
      return <WarningRow animated={false}>{children}</WarningRow>
    
    case UploadStatus.Error:
    case UploadStatus.MissingColor:
    case UploadStatus.Aborted:
        return <ErroredRow>{children}</ErroredRow>

    case UploadStatus.Queued:
      return <QueuedRow animated={false}>{children}</QueuedRow>
  
    case UploadStatus.Success:
      return <SuccessRow>{children}</SuccessRow>

    default:
      return <tr><td>default</td></tr>;
  }
}


const WarningRow = (props: { animated: boolean, children: JSX.Element[] }) => {
  const style = {
    ...props.animated ? {
      backgroundImage: "linear-gradient(90deg, rgba(255, 255, 255, 0) 100%, #fff 0%), repeating-linear-gradient(-45deg, rgba(255, 228, 130, 0.75), rgba(255, 228, 130, 0.75) 25px, rgb(255, 228, 130) 25px, rgb(255, 228, 130) 50px)"
    } : {
      backgroundColor: "rgb(255, 228, 130)"
    }
  };

  return (
    <tr className={styles.animationStripes} style={style}>
      {props.children}
    </tr>
  )
}

const QueuedRow = (props: { animated: boolean, children: JSX.Element[] }) => {
  const style = {
    ...props.animated ? {
      backgroundImage: "linear-gradient(90deg, rgba(255, 255, 255, 0) 100%, #fff 0%), repeating-linear-gradient(-45deg, rgba(199, 199, 199, 0.75), rgba(199, 199, 199, 0.75) 25px, rgb(199, 199, 199) 25px, rgb(199, 199, 199) 50px)"
    } : {
      backgroundColor: "rgb(199, 199, 199)"
    }
  };

  return (
    <tr className={styles.animationStripes} style={style}>
      {props.children}
    </tr>
  )
}

const ProgressRow = (props: { children: JSX.Element[], progress: number }) => {
  const style = {
    backgroundImage: `linear-gradient(90deg, rgba(255, 255, 255, 0) ${props.progress}%, #fff 0%), repeating-linear-gradient(-45deg, rgba(17, 154, 145, 0.75), rgba(17, 154, 145, 0.75) 25px, rgb(17, 154, 145) 25px, rgb(17, 154, 145) 50px)`
  };

  return (
    <tr className={styles.animationStripes} style={style}>
      {props.children}
    </tr>
  )
}

const SuccessRow = (props: { children: JSX.Element[] }) => {
  const style = {
    backgroundColor: "rgb(17, 154, 145)"
  };

  return (
    <tr className={styles.animationStripes} style={style}>
      {props.children}
    </tr>
  )
}

const ErroredRow = (props: { children: JSX.Element[] }) => {
  const style = {
    backgroundColor: "rgb(250, 227, 225)"
  };

  return (
    <tr className={styles.animationStripes} style={style}>
      {props.children}
    </tr>
  )
}

const DeleteButton = (props: { children: ReactNode, title?: string, onClick: () => void}) => (
  <button type="button" title={props.title} className={styles.deleteButton} onClick={props.onClick}>
    {props.children}
  </button>
);

const RetryButton = (props: { children: ReactNode, title?: string, onClick: () => void}) => (
  <button type="button" title={props.title} className={styles.retryButton} onClick={props.onClick}>
    {props.children}
  </button>
);